<template>
  <div class="event">
    <div class="event__wrapper">
      <div
        class="event__header-background"
        :style="{
          backgroundImage: `url(${event.header_image})`,
        }"
      ></div>
      <div class="event__main-container">
        <div class="event__details-box">
          <span class="event__details-box_data">{{ event.formated_dates }} | {{ event.type }}</span>
          <h2 class="event__details-box_title">{{ event.title }}</h2>
          <span class="event__details-box_details">{{ event.description }}</span>
          <BaseButton
            v-if="event.button_text && event.button_url"
            class="button button--xs button--outline-white button--uppercase"
            :text="event.button_text"
            @click="goToLink(event.button_url)"
          />
        </div>

        <div class="event__info-box">
          <span class="event__info-box_data"
            >{{ event.formated_start_date }} - {{ event.formated_end_date }}</span
          >
          <span class="event__info-box_time"
            >{{ event.formated_start_time }} - {{ event.formated_end_time }}</span
          >
          <BaseButton
            v-if="event.button_rsvp_url"
            class="button button--xs button--white"
            text="RSVP"
            @click="goToLink(event.button_rsvp_url)"
          />

          <template v-if="event.address">
            <li class="event__info-box_section"><span>ADDRESS</span></li>
            <span class="event__info-box_content">{{ event.address }}</span>
          </template>

          <template v-if="event.safety_protocol">
            <li class="event__info-box_section"><span>SAFETY PROTOCOL</span></li>
            <div
              v-html="event.safety_protocol_info"
              class="event__info-box_content collect-editor collect-editor--white collect-editor--thin"
            ></div>
          </template>
        </div>

        <div v-if="exhibition.title" class="event__special-event-box">
          <span class="event__special-event-box_data"
            >{{ exhibition.dates }} | {{ exhibition.type }}</span
          >
          <span class="event__special-event-box_title">{{ exhibition.title }}</span>
          <BaseButton
            class="button button--xs button--white"
            text="VIEW ASSOCIATED EXHIBITION"
            @click="$router.push({ path: `/exhibitions/${exhibition.slug}` })"
          />
        </div>
        <div class="event__sponsors-box" v-if="event.sponsors.length > 0">
          <div class="event__sponsors-box_sponsors event__column">
            <h3 class="title">Sponsors</h3>
            <div class="container">
              <a
                v-for="(item, key) in event.sponsors"
                :key="`sponsor-${key}`"
                class="container__item sponsor-link"
                :class="{ 'sponsor-link--no-clickable': !item.url }"
                :href="item.url ? item.url : ''"
                target="_blank"
                @click.prevent="goToLink(item.url)"
              >
                <span class="container__item_box">
                  <img class="container__item_logo" :src="item.image" alt="Sponsor logo" />
                </span>
                <span>{{ item.caption }}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import BaseButton from "@/components/buttons/BaseButton.vue";

export default {
  components: { BaseButton },

  data() {
    return {};
  },
  head: {
    title: function () {
      return {
        inner: this.event.title ?? "Event browngrotta arts",
      };
    },
    meta: function () {
      return [
        {
          name: "description",
          itemprop: "description",
          content: `${this.event.title ?? "Event browngrotta arts"} - ${
            this.event.description ?? "Description Event browngrotta arts"
          }`,
          id: "meta:description",
        },
        {
          itemprop: "name",
          content: this.event.title ?? "Event browngrotta arts",
          id: "meta:name",
        },
        {
          itemprop: "image",
          content:
            this.event.header_image ??
            `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "meta:image",
        },
        {
          property: "og:url",
          content: `${process.env.VUE_APP_FRONTEND_URL}${this.$route.path}`,
          id: "og:url",
        },
        {
          property: "og:type",
          content: "website",
          id: "og:type",
        },
        {
          property: "og:title",
          content: this.event.title ?? "Event browngrotta arts",
          id: "og:title",
        },
        {
          property: "og:image",
          content:
            this.event.header_image ??
            `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "og:image",
        },
        {
          property: "og:image:alt",
          content: this.event.title ?? "Event browngrotta arts",
          id: "og:image:alt",
        },
        {
          property: "og:description",
          content: this.event.description ?? "Description Event browngrotta arts",
          id: "og:description",
        },
        {
          property: "og:site_name",
          content: process.env.VUE_APP_FRONTEND_URL,
          id: "og:site_name",
        },
        {
          property: "og:locale",
          content: "en_US",
          id: "og:locale",
        },
        {
          name: "twitter:card",
          content: "summary",
          id: "twitter:card",
        },
        {
          name: "twitter:site",
          content: "@BrowngrottaArts",
          id: "twitter:site",
        },
        {
          name: "twitter:creator",
          content: "@BrowngrottaArts",
          id: "twitter:creator",
        },
        {
          name: "twitter:url",
          content: `${process.env.VUE_APP_FRONTEND_URL}${this.$route.path}`,
          id: "twitter:url",
        },
        {
          name: "twitter:title",
          content: this.event.title ?? "Event browngrotta arts",
          id: "twitter:title",
        },
        {
          name: "twitter:description",
          content: this.event.description ?? "Description Event browngrotta arts",
          id: "twitter:description",
        },
        {
          name: "twitter:image",
          content:
            this.event.header_image ??
            `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "twitter:image",
        },
        {
          name: "twitter:image:alt",
          content: this.event.title ?? "Event browngrotta arts",
          id: "twitter:image:alt",
        },
      ];
    },
  },
  computed: {
    ...mapGetters("publicapi/events", {
      event: "getSpecificEvent",
      exhibition: "getAssociatedExhibition",
    }),
  },
  async created() {
    await this.apiGetSpecificEvent(this.$route.params.slug);
    this.$emit("updateHead");
    const breadcrumbs = [
      {
        title: "Home",
        to: "/",
      },
      {
        title: "Events",
        to: "/events/events",
        clickable: true,
      },
      {
        title: `${this.event.title}`,
        clickable: false,
      },
    ];
    this.setBreadcrumbs(breadcrumbs);

    this.setShareData({
      title: this.event.title,
      description: this.event.description,
      image: this.event.header_image,
    });
  },

  methods: {
    ...mapMutations(["setBreadcrumbs"]),
    ...mapMutations("publicapi/share", ["setShareData"]),
    ...mapActions("publicapi/events", ["apiGetSpecificEvent"]),

    goToLink(url) {
      if (url) {
        window.open(url, "_blank");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  color: $white;
  font-size: 18px;
  line-height: 1.3;
  @media screen and (max-width: $xs) {
    font-size: 16px;
    line-height: 1.5;
  }
}

.event {
  display: flex;

  &__header {
    &-background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 58.4rem;
      z-index: 0;
      background-size: auto 100%;
      background-position: center;
      background-repeat: no-repeat;
      @media screen and (max-width: $xs) {
        height: 33.5rem;
      }
      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba($color: $lightBlack, $alpha: 0.45);
        z-index: 1;
      }
      &::after {
        content: "";
        bottom: -1px;
        position: absolute;
        width: 100%;
        height: 50%;
        background: linear-gradient(
          0deg,
          rgba($color: $lightBlack, $alpha: 1) 0%,
          rgba($color: $lightBlack, $alpha: 0) 100%
        );
        z-index: 1;
      }
    }
  }

  &__wrapper {
    position: relative;
    min-height: 100vh;
    min-width: 100vw;
  }

  &__main-container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 40rem;
    padding: 0rem 10.5rem 23.5rem;
    z-index: 2;
    @media screen and (max-width: $md) {
      margin-top: 24.5rem;
    }
    @media screen and (max-width: $xs) {
      padding: 0rem 2rem 24rem;
    }
  }

  &__details-box {
    display: flex;
    flex-direction: column;
    width: 60%;
    @media screen and (max-width: $md) {
      width: 100%;
    }

    &_data {
      font-size: 16px;
      line-height: 1.6;
      text-transform: uppercase;
      @media screen and (max-width: $xs) {
        font-size: 14px;
      }
    }

    &_title {
      font-size: 40px;
      line-height: 1.25;
      margin-bottom: 17px;
      @media screen and (max-width: $xs) {
        font-size: 3.5rem;
        line-height: 4.3rem;
      }
    }

    &_details {
      font-family: $fontFrutiger;
      font-weight: 100;
    }

    .button {
      margin-top: 22px;
      @media screen and (max-width: $xs) {
        margin-top: 35px;
      }
    }
  }

  &__info-box {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 2.5rem 0rem 0rem 14.2rem;
    width: 40%;
    @media screen and (max-width: $md) {
      width: 100%;
      padding: 9.5rem 0rem 0rem 0rem;
    }

    .button {
      max-width: 180px;
      margin: 2rem 0rem 1rem;
    }

    &_section {
      margin: 1.5rem 0rem 1rem;
    }

    &_content {
      font-family: $fontFrutiger;
      font-weight: 100;
      margin-left: 10px;
    }

    &_info {
      display: flex;
      flex-direction: column;
      margin-bottom: 25px;
    }

    &_data {
      font-size: 27px;
      line-height: 1.85;
    }

    &_time {
      font-size: 27px;
      line-height: 1.85;
    }

    li {
      span {
        font-size: 16px;
        font-weight: 700;
        margin-left: -12px;
        @media not all and (min-resolution: 0.001dpcm) {
          @supports (-webkit-appearance: none) {
            margin-left: 0px;
          }
        }
        @supports (-moz-appearance: none) {
          margin-left: 0px;
        }
      }
    }
  }

  &__special-event-box {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 17.6rem;
    width: 100%;

    &_data {
      font-size: 16px;
      line-height: 1.56;
      text-transform: uppercase;
    }

    &_title {
      font-size: 40px;
      line-height: 1.25;
      display: inline-block;
      text-align: center;
      @media screen and (max-width: $xs) {
        font-size: 35px;
        line-height: 1.2;
      }
    }

    .button {
      margin-top: 35px;
    }
  }
  &__sponsors-box {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 17.6rem;
    @media screen and (max-width: $lg) {
      justify-content: flex-start;
      margin-top: 15rem;
    }
    @media screen and (max-width: $md) {
      margin-top: 10rem;
    }
    @media screen and (max-width: $xs) {
      margin-top: 7.5rem;
    }

    .title {
      font-size: 4.65rem;
      line-height: 1.3;
      margin-bottom: 35px;
      @media screen and (max-width: $xs) {
        margin-bottom: 40px;
      }
    }

    &_sponsors {
      width: 100%;
      @media screen and (max-width: $md) {
        margin-bottom: 4rem;
      }

      .container {
        width: calc(100% + 30px);
        margin-left: -15px;
        display: flex;
        // align-items: center;
        flex-wrap: wrap;

        &__item {
          max-width: 23.4rem;
          margin: 0 15px 30px;
          // &_logo {
          //   height: 100%;
          //   width: 100%;
          //   border-radius: 17px;
          //   object-fit: cover;
          // }
          &_box {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 23.4rem;
            width: 23.4rem;
            border-radius: 17px;
            cursor: pointer;
            overflow: hidden;
            text-decoration: none;
            border: 1px solid $white;
            margin-bottom: 10px;
          }
        }
      }
      .sponsor-link {
        text-decoration: none;
        font-size: 18px;
        &--no-clickable {
          cursor: default;
        }
      }
    }
  }
}
</style>

